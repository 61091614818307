import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import React from "react";

type ExtendedButtonProps = ButtonProps & { href?: string };

type Props = {
  tagline: string;
  heading: string;
  description: string;
  buttons: ExtendedButtonProps[];
};

export type SelfcareProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Selfcare = (props: SelfcareProps) => {
  const { tagline, heading, description, buttons } = {
    ...SelfcareDefaults,
    ...props,
  } as Props;

  return (
    <header className="px-[5%] py-16 md:py-24 lg:py-28 bg-[url(../assets/images/selfcare_bg.png)] bg-cover bg-center bg-no-repeat rounded-lg shadow-md">
      <div className="container max-w-2xl mx-auto bg-white bg-opacity-80 p-8 rounded-lg shadow-lg text-center">
        <p className="mb-3 text-lg font-semibold text-gray-600">{tagline}</p>
        <h1 className="mb-5 text-4xl font-bold text-black md:mb-6 md:text-5xl lg:text-6xl">
          {heading}
        </h1>
        <p className="mb-6 text-base text-gray-700 md:text-lg">{description}</p>
        <div className="mt-6 flex flex-col items-center justify-center gap-y-4 md:mt-8 md:flex-row md:gap-x-4">
          {buttons.map((button, index) => (
            <a key={index} href={button.href || "#"}>
              <Button
                {...button}
                className="rounded-full py-2 px-6"
                style={
                  button.title === "Join Us"
                    ? { backgroundColor: "#EDED00", color: "#000" }
                    : {}
                }
              >
                {button.title}
              </Button>
            </a>
          ))}
        </div>
      </div>
    </header>
  );
};

export const SelfcareDefaults: SelfcareProps = {
  tagline: "Self Care",
  heading: "Begin Your Self-Improvement Journey with Your CBT Coach",
  description: `Your CBT Coach is a personalized self-help tool based on Cognitive Behavioral Therapy.
This interactive seven-step program helps you understand your thoughts, feelings,
behaviors, and insecurities. Through text or voice interaction, you’ll gain key coping skills
and strategies. Powered by My-E-Health's proven tools and advanced AI, Your CBT Coach
offers an engaging experience. Whether you’re addressing concerns from your My-E-Health
assessments or other issues, you can revisit your self-help journey anytime.
    `,
  buttons: [
    {
      title: "Contact Us",
      href: "https://register.my-e-health.com/contact",
    },
    {
      title: "Join Us",
      variant: "secondary",
      href: "https://register.my-e-health.com/start-registration-B2C",
    },
  ],
};

Selfcare.displayName = "Selfcare";
