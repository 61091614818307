import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { motion } from "framer-motion";
import { BiCheck } from "react-icons/bi";
import { FaBrain, FaAppleAlt, FaDumbbell, FaBed } from "react-icons/fa";

type Billing = "monthly" | "quarterly" | "yearly";

type PlanDetails = {
  iconClassName: string;
  planName: string;
  price: number;
  originalPrice?: number;
  description: string;
  button: ButtonProps;
  discount?: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  plans: {
    monthly: PlanDetails;
    quarterly: PlanDetails;
    yearly: PlanDetails;
  };
};

export type PricingProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Pricing = (props: PricingProps) => {
  const { tagline, heading, description, plans } = {
    ...PricingDefaults,
    ...props,
  } as Props;

  return (
    <section className="px-[5%] py-12 md:py-16 lg:py-20 bg-gray-50">
      <div className="container mx-auto">
        <div className="mx-auto mb-8 max-w-xl text-center md:mb-12 lg:mb-16">
          <div className="mb-4 text-3xl font-semibold text-gray-500">
            {tagline}
          </div>
          <h2 className="mb-6 text-4xl font-bold text-gray-800 md:text-5xl lg:text-6xl">
            {heading}
          </h2>
          <p className="text-lg text-gray-600 md:text-xl">{description}</p>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
        >
          <PlanComponent plan={plans.monthly} billing="monthly" />
          <PlanComponent plan={plans.quarterly} billing="quarterly" />
          <PlanComponent plan={plans.yearly} billing="yearly" isRecommended />
        </motion.div>
        <BenefitSection /> {/* Integrating the BenefitSection component here */}
      </div>
    </section>
  );
};

const PlanComponent = ({
  plan,
  billing,
  isRecommended = false,
}: {
  plan: PlanDetails;
  billing: Billing;
  isRecommended?: boolean;
}) => {
  return (
    <div
      className={`relative flex h-full flex-col justify-between bg-white rounded-lg p-6 shadow-md transform transition-transform hover:scale-105 hover:shadow-lg ${
        isRecommended ? "border-2 border-yellow-400" : "border border-gray-200"
      }`}
      style={{ minHeight: "450px" }}
    >
      {isRecommended && (
        <div className="absolute top-0 right-0 bg-yellow-500 text-white text-xs font-bold px-3 py-1 rounded-bl-lg">
          Recommended
        </div>
      )}
      <div className="flex-grow flex flex-col justify-center items-center text-center">
        <div className="mb-4">
          <div className={`text-5xl mb-3 ${plan.iconClassName}`} />
          <h5 className="text-xl font-bold text-gray-800">
            {billing === "quarterly" ? "Quarterly Plan" : plan.planName}
          </h5>
        </div>
        <p className="text-gray-600 mb-4">{plan.description}</p>
        <div className="text-center">
          {plan.originalPrice && (
            <h1 className="text-2xl line-through text-gray-500">
              £{plan.originalPrice}
            </h1>
          )}
          <h1 className="text-4xl font-bold text-gray-900">
            £{plan.price}
            <span className="text-lg text-gray-600">
              /
              {billing === "monthly"
                ? "mo"
                : billing === "quarterly"
                ? "qtr"
                : "yr"}
            </span>
          </h1>
          {plan.discount && (
            <p className="mt-2 text-sm font-medium text-red-500">
              {plan.discount}
            </p>
          )}
        </div>
      </div>
      <Button
        variant={plan.button.variant}
        size="sm"
        className="w-full bg-yellow-400 text-black rounded-full py-3 mt-6"
        onClick={() =>
          (window.location.href =
            "https://register.my-e-health.com/start-registration-B2C")
        }
      >
        {plan.button.title}
      </Button>
    </div>
  );
};

const BenefitSection = () => {
  return (
    <section className="px-[5%] py-12 bg-gradient-to-b from-gray-50 to-gray-100 mt-12">
      <div className="container mx-auto">
        <h3 className="text-3xl font-bold text-center mb-10 text-gray-800">
          The Plans Includes
        </h3>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className=" text-2xl mb-6 text-gray-800 border-b-2 pb-2">
              Elite Personalized AI Support For:
            </h4>
            <ul className="space-y-4">
              <li className="flex items-center mb-2">
                <FaBrain className="text-teal-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Mental Health
                </span>
              </li>
              <li className="flex items-center mb-3">
                <FaAppleAlt className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Nutrition
                </span>
              </li>
              <li className="flex items-center mb-3">
                <FaDumbbell className="text-orange-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">Fitness</span>
              </li>
              <li className="flex items-center mb-3">
                <FaBed className="text-purple-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">Sleep</span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className="text-2xl mb-6 text-gray-800 border-b-2 pb-2">
              You Also Get the Extra Benefits Of:
            </h4>
            <ul className="space-y-4">
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Health Tracking
                </span>
              </li>
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Personalized Health Reports
                </span>
              </li>
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Monthly Health Reports
                </span>
              </li>
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Customer Support
                </span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className=" text-2xl mb-5 text-gray-800 border-b-2 pb-2 lg:pb-[48px]">
              Additional Benefits:
            </h4>
            <ul className="space-y-4">
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Access to Health Professionals
                </span>
              </li>
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  Garmin (Smartwatch) Integration
                </span>
              </li>
              <li className="flex items-center">
                <BiCheck className="text-green-500 mr-3" size={28} />
                <span className="text font-medium text-gray-700">
                  24/7 Access to AI Health Coaches
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-[10px] mt-5 items-center justify-center self-stretch shrink-0 flex-nowrap relative ">
          <span className="text-center w-full h-auto font-['Urbanist'] text-[30px] md:text-[45px] font-bold leading-[40px] md:leading-[64px] text-gray-800 relative ">
            “ We Are the Gold Standard in Digital Health Solutions! ”
          </span>
        </div>
      </div>
    </section>
  );
};

export const PricingDefaults: PricingProps = {
  tagline: "",
  heading: "Flexible Pricing Plans",
  description: "",
  plans: {
    monthly: {
      iconClassName: "fas fa-calendar-alt text-yellow-400",
      planName: "Monthly Plan",
      description: "Access essential health services on a monthly basis.",
      price: 19,
      button: { title: "Get Started", variant: "primary", size: "sm" },
    },
    quarterly: {
      iconClassName: "fas fa-calendar-check text-green-400",
      planName: "Quarterly Plan",
      description: "Access essential health services on a quarterly basis.",
      originalPrice: 57,
      price: 51.3,
      discount: "10% off the quarterly price + 1 month free",
      button: { title: "Get Started", variant: "primary", size: "sm" },
    },
    yearly: {
      iconClassName: "fas fa-calendar text-red-400",
      planName: "Yearly Plan",
      description: "Enjoy all yearly benefits with an additional discount.",
      originalPrice: 228,
      price: 136.8,
      discount: "40% off the yearly price + 1 month free",
      button: { title: "Get Started", variant: "primary", size: "sm" },
    },
  },
};

Pricing.displayName = "Pricing";
