import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogPortal,
  DialogOverlay,
} from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { RxChevronRight } from "react-icons/rx";
import clsx from "clsx";
import "./index.css";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  buttons: (ButtonProps & { path?: string })[]; // Added path to button type
  video: string;
  image: ImageProps;
};

export type AboutProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const About = (props: AboutProps) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const { tagline, heading, description, buttons, video, image } = {
    ...AboutDefaults,
    ...props,
  } as Props;

  return (
    <header className="px-[5%] py-16 md:py-24 lg:py-28 bg-white rounded-lg shadow-md">
      <div className="container">
        <div className="grid grid-cols-1 gap-y-12 md:grid-cols-2 md:items-center md:gap-x-12 lg:gap-x-20">
          <Dialog>
            <DialogTrigger>
              <div className="relative flex w-full max-w-full items-center justify-center">
                <div
                  role="img"
                  className="w-full h-auto max-h-96 rounded-lg shadow object-cover about-us-image"
                  aria-label={image.alt}
                  style={{ aspectRatio: "16 / 9" }} // Ensures the image maintains a 16:9 aspect ratio
                />
                <Play className="absolute size-20 text-white" />
                <span className="absolute inset-0 bg-black/50 rounded-lg" />
              </div>
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay className="bg-black/90" />
              <DialogContent>
                {!isIframeLoaded && (
                  <Loading className="mx-auto size-16 text-white" />
                )}
                <iframe
                  className={clsx(
                    "z-0 mx-auto aspect-video h-full w-full md:w-[738px] lg:w-[940px] rounded-lg",
                    {
                      visible: isIframeLoaded,
                      hidden: !isIframeLoaded,
                    }
                  )}
                  src={video}
                  allow="autoplay; encrypted-media; picture-in-picture"
                  allowFullScreen
                  onLoad={() => setIsIframeLoaded(true)}
                ></iframe>
              </DialogContent>
            </DialogPortal>
          </Dialog>
          <div>
            <h3 className="mb-1 text-lg font-bold uppercase text-slate-500">
              {tagline}
            </h3>
            {/* <h2 className="mb-4 text-xl font-semibold text-gray-700 md:mb-5 md:text-2xl lg:text-3xl">
              Proven E-Health Solutions for Your Well-Being
            </h2> */}

            <p className="md:text-md mb-5">{description}</p>
            <div className="mt-6 flex items-center gap-x-4 md:mt-8">
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  className={button.title === "Sign Up" ? "rounded-full" : ""}
                  onClick={() =>
                    button.path && (window.location.href = button.path)
                  }
                  style={
                    button.title === "Sign Up"
                      ? { backgroundColor: "#EDED00", color: "black" }
                      : {}
                  }
                >
                  {button.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export const AboutDefaults: AboutProps = {
  tagline: "About Us",
  heading: "Evidence-Based E-Health Solutions for Optimal Well-Being",
  description:
    "My-E-Health is your all-in-one digital health platform that helps you take control of your mental health. With trusted assessments, tailored advice, and self-help tools, it supports youin preventing, treating, and managing mental health issues.",
  buttons: [
    {
      title: "Sign Up",
      path: "https://register.my-e-health.com/start-registration-B2C",
    },
    {
      title: "Read More",
      variant: "link",
      size: "link",
      iconRight: <RxChevronRight className="size-6" />,
      path: "https://www.my-e-health.com/aboutUs",
    },
  ],
  video: "https://www.youtube.com/embed/fcBMOI0FXDY?si=c_YiBD-tS8c3KfJy",
  image: {
    src: "../assets/images/aboutus.png",
    alt: "Placeholder image",
  },
};

const Play = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.333 32C5.333 17.272 17.273 5.333 32 5.333A26.667 26.667 0 0 1 58.666 32c0 14.728-11.939 26.667-26.666 26.667-14.728 0-26.667-11.94-26.667-26.667ZM27.12 43.413l15.546-9.706a2.027 2.027 0 0 0 0-3.414l-15.6-9.706A2 2 0 0 0 24 22.267v19.466a2 2 0 0 0 3.12 1.68Z"
      />
    </svg>
  );
};

const Loading = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="currentColor">
        <path
          strokeDasharray={60}
          strokeDashoffset={60}
          strokeOpacity={0.3}
          d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18Z"
        >
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            dur="1.3s"
            values="60;0"
          />
        </path>
        <path
          strokeDasharray={15}
          strokeDashoffset={15}
          d="M12 3a9 9 0 0 1 9 9"
        >
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            dur="0.3s"
            values="15;0"
          />
          <animateTransform
            attributeName="transform"
            dur="1.5s"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 12;360 12 12"
          />
        </path>
      </g>
    </svg>
  );
};

About.displayName = "About";
