import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";

const slides = [
  {
    title: "Live Your Dream Life",
    description: (
      <>
        Our service empowers you to take control
       
        of your mental health{" "}
        <span className="font-bold text-yellow-300">
          without stigma
        </span>
        ,{" "}
        <span className="font-bold text-yellow-300">
          frustration
        </span>
        , or{" "}
        <span className="font-bold text-yellow-300">
          delays
        </span>
        .
      </>
    ),
  },
];

const Hero = () => {
  const handleSignUp = () => {
    window.location.href =
      "https://register.my-e-health.com/start-registration-B2C";
  };

  return (
    <div className="relative flex flex-col w-full h-screen bg-cover bg-no-repeat bg-center hero-bg">

      <div className="absolute left-4 right-4 top-32 w-full h-full flex items-center justify-center md:justify-start font-ainslie">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          transitionTime={600}
          stopOnHover={false}
          showArrows={false}
          showIndicators={false}
          className="h-full w-full"
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="h-full flex items-center justify-start p-4 md:p-14"
            >
              <div className="flex flex-col gap-4 md:gap-6  md:text-left">
                <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-6xl font-bold text-white">
                  {slide.title}
                </h1>
                <p className="text-lg sm:text-xl md:text-2xl text-white max-w-lg">
                  {slide.description}
                </p>
                <div className="w-full flex justify-center md:justify-start mt-6 md:mt-8">
                  <button
                    className="p-2 sm:p-3 px-4 sm:px-[35px] bg-[#EDED00] text-black text-base sm:text-lg font-semibold rounded-full shadow-lg hover:bg-[#c5c500] transition-colors duration-300"
                    onClick={handleSignUp}
                    aria-label="Sign Up"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Hero;
